<template>
  <td>
    <!-- {{ day }} -->
    <span v-if="day">{{ Math.floor((totalDay.CATotal - totalDay.mavro) * 100) / 100 }}</span>
    <span v-else>&#x2205;</span>
  </td>
</template>

<script>
export default {
  name: 'DayDisplay',
  props: {
    day: String,
    daysDataList: Array
  },
  watch: {
    daysDataList (newVal) {
      if (newVal !== '') {
        this.getDayData2()
      }
    }
  },
  data () {
    return {
      totalDay: {}
    }
  },
  methods: {
    // getDayData (key) { // obsolete, isn't called
    //   if (!key) {
    //     // console.log('returning')
    //     return
    //   }
    //   const sentBody = this.$genFD({
    //     req: 'registerController',
    //     action: 'getDay',
    //     date: key
    //   })

    //   fetch('https://compta.lucasgrd.fr/db/', {
    //     body: sentBody,
    //     method: 'post'
    //   })
    //     .then(res => res.json())
    //     .then(data => {
    //       this.totalDay = data.results[0]

    //       this.$emit('gotTotal', {
    //         register: this.totalDay.register === 0 ? this.totalDay.CATotal : this.totalDay.register,
    //         mavro: this.totalDay.mavro
    //       })
    //     })
    //     .catch(err => {
    //       this.$swal({
    //         icon: 'error',
    //         toast: true,
    //         timer: 3000,
    //         position: 'top-end',
    //         title: 'Les comptes n\'ont pas pu être Lus',
    //         html: 'Veuillez contacter votre administrateur (ton frère). Erreur : ' + err,
    //         showConfirmButton: false
    //       })
    //     })
    // },
    getDayData2 () {
      if (this.day) {
        this.totalDay = this.daysDataList.find(day => day.date === this.day)
        this.$emit('gotTotal', {
          register: this.totalDay.register === 0 ? this.totalDay.CATotal : this.totalDay.register,
          mavro: this.totalDay.mavro
        })
      }
    }
  },
  beforeMount () {
    this.$emit('resetTotal')
  },
  mounted () {
    // this.getDayData(this.day)
  }
}
</script>
