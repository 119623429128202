<template>
  <div class="main">
    <img alt="Vue logo" src="./assets/logo.png" @click="counter++"/>
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <CalculatorComponent v-if="ready" :mavro="mavro" @updateHistory="updateHistory"/>
    <button @click="showHistory = !showHistory"><span class="invis">&#10097;</span>Historique quotidien<span :class="showHistory ? 'rotate' : ''">&#10097;</span></button>
    <HistoryComponent v-if="ready && showHistory" :mavro="mavro" :update="update" @deleted="updateHistory"/>
    <button @click="showWeeklyHistory = !showWeeklyHistory"><span class="invis">&#10097;</span>Historique hebdomadaire<span :class="showWeeklyHistory ? 'rotate' : ''">&#10097;</span></button>
    <WeekManager v-if="ready && showWeeklyHistory" :mavro="mavro" :update="update" @showReport="buildWeeklyReport" @buildYearlyReport="buildYearlyReport" @deleteYearlyReport="deleteYearlyReport"/>
    <WeeklyReport ref="report" v-if="reportedWeek && !readyYearly" :week="reportedWeek"/>
    <YearlyReport v-if="readyYearly" :startWeek="startWeek" :endWeek="endWeek"/>
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import CalculatorComponent from './components/CalculatorComponent.vue'
import HistoryComponent from './components/HistoryComponent.vue'
import WeekManager from './components/WeekManager.vue'
import WeeklyReport from './components/WeeklyReport.vue'
import YearlyReport from './components/YearlyReport.vue'

export default {
  name: 'App',
  components: {
    // HelloWorld,
    CalculatorComponent,
    HistoryComponent,
    WeekManager,
    WeeklyReport,
    YearlyReport
  },
  data () {
    return {
      db: null,
      ready: false,
      update: false,
      reportedWeek: null,
      showHistory: false,
      showWeeklyHistory: false,
      readyYearly: false,
      startWeek: '',
      endWeek: '',
      counter: 0,
      mavro: true // if true, displays mavro. else, hides it
    }
  },
  watch: {
    reportedWeek () {
      setTimeout(() => {
        this.$refs.report.$el.scrollIntoView({ behavior: 'smooth' })
      }, 500)
    },
    counter (newVal) {
      if (newVal === 5) {
        this.toggleMavro()
        this.counter = 0
      }
    }
  },
  methods: {
    toggleMavro () {
      this.mavro = !this.mavro
      localStorage.setItem('mavro', this.mavro)
    },
    deleteYearlyReport () {
      this.readyYearly = false
    },
    buildYearlyReport (payload) {
      // console.log('buildYearly', payload)
      if (this.readyYearly === true) {
        this.readyYearly = false
      }
      this.startWeek = payload.startWeek
      this.endWeek = payload.endWeek
      this.reportedWeek = null
      this.readyYearly = true
    },
    buildWeeklyReport (payload) {
      // console.log('payload:', payload)
      this.reportedWeek = payload
      this.readyYearly = false
    },
    updateHistory () {
      // console.log('updating')
      this.update = !this.update
      this.showHistory = true
    },
    async getDb () {
      return new Promise((resolve, reject) => {
        const request = window.indexedDB.open('sebastoviandes', 3)

        request.onerror = e => {
          // console.log('Error opening db', e)
          reject(e)
        }

        request.onsuccess = e => {
          resolve(e.target.result)
        }
        request.onupgradeneeded = e => {
          const db = e.target.result

          // Create an objectStore to hold information about our customers. We're
          // going to use "ssn" as our key path because it's guaranteed to be
          // unique - or at least that's what I was told during the kickoff meeting.
          const objectStore = db.createObjectStore('caisses', { keyPath: 'date' })

          // Create an index to search customers by name. We may have duplicates
          // so we can't use a unique index.
          objectStore.createIndex('CATotal', 'CATotal', { unique: false })

          // Create an index to search customers by email. We want to ensure that
          // no two customers have the same email, so use a unique index.
          // objectStore.createIndex('costs', 'costs', { unique: false })
          // objectStore.createIndex('MC', 'MC', { unique: false })
          // objectStore.createIndex('coeff', 'coeff', { unique: false })
          objectStore.createIndex('ISODate', 'ISODate', { unique: true })
          objectStore.createIndex('cash', 'cash', { unique: false })
          objectStore.createIndex('card', 'card', { unique: false })
          objectStore.createIndex('tickets', 'tickets', { unique: false })
          objectStore.createIndex('nbTickets', 'nbTickets', { unique: false })
          objectStore.createIndex('check', 'check', { unique: false })
          objectStore.createIndex('register', 'register', { unique: false })
          objectStore.createIndex('mavro', 'mavro', { unique: false })

          // deuxieme table
          const objectStore2 = db.createObjectStore('semaines', { keyPath: 'date' })
          objectStore2.createIndex('costs', 'costs', { unique: false })
          objectStore2.transaction.oncomplete = (event) => {
            // console.log('completed objectstore2')
          }
          // Use transaction oncomplete to make sure the objectStore creation is
          // finished before adding data into it.
          objectStore.transaction.oncomplete = (event) => {
            // console.log('completed objectstore')
            // Store values in the newly created objectStore.
            // const caissesObjectStore = db
            //   .transaction("caisses", "readwrite")
            //   .objectStore("caisses")
            // customerData.forEach((customer) => {
            //   caissesObjectStore.add(customer)
            // })
          }
        }
      })
    }
  },
  async created () {
    // this.db = await this.getDb()
    // this.cats = await this.getCatsFromDb();
    this.ready = true
    if (localStorage.getItem('mavro')) {
      this.mavro = localStorage.getItem('mavro') === 'true'
    } else {
      this.mavro = true
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  min-height: 200vh;
}
</style>

<style scoped>
button {
  width: 100%;
  padding: 8px 20px;
  font-size: xx-large;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: .2s;
  transform: rotate(0deg);
}
.rotate {
  transition: .2s;
  transform: rotate(90deg);
}
button:hover {
  cursor: pointer;
}
.invis {
  visibility: hidden;
}
</style>
