<template>
  <main>
    <section id="vflex">
      <div class="flex">
        <span>
          <label>Date</label>
          <input v-model="date" type="text" />
        </span>
      </div>
      <div class="flex">
        <span>
          <label>CA Espèces</label>
          <span><input @focus="CA.cash == 0 ? CA.cash = '' : CA.cash" @blur="CA.cash == '' ? CA.cash = 0 : CA.cash" v-model="CA.cash" type="number"/>€</span>
        </span>
        <span>
          <label>CA CB</label>
          <span><input @focus="CA.card == 0 ? CA.card = '' : CA.card" v-model="CA.card" @blur="CA.card == '' ? CA.card = 0 : CA.card" type="number"/>€</span>
        </span>
        <span>
          <label>CA Tickets</label>
          <span><input @focus="CA.tickets == 0 ? CA.tickets = '' : CA.tickets" @blur="CA.tickets == '' ? CA.tickets = 0 : CA.tickets" v-model="CA.tickets" type="number"/>€</span>
          <label>Nb Tickets</label>
          <span><input @focus="nbTickets == 0 ? nbTickets = '' : nbTickets" @blur="nbTickets == '' ? nbTickets = 0 : nbTickets" v-model="nbTickets" type="number"/></span>
        </span>
        <span>
          <label>CA Chèques</label>
          <span><input @focus="CA.checks == 0 ? CA.checks = '' : CA.checks" @blur="CA.checks == '' ? CA.checks = 0 : CA.checks" v-model="CA.checks" type="number"/>€</span>
        </span>
      </div>
      <div v-show="mavro" class="flex">
        <span>
          <label>Recette Totale Déclarée</label>
          <span><input @focus="CA.register == 0 ? CA.register = '' : CA.register" @blur="CA.register == '' ? CA.register = 0 : CA.register" v-model="CA.register"  type="number"/>€</span>
        </span>
        <span>
          <label>Total Mavro</label>
          <span><input v-model="CA.mavro" type="number" readonly/>€</span>
        </span>
      </div>
      <div class="flex">
        <span v-if="mavro">
          <label>Total CA</label>
          <span><input v-model="CATotal" type="number" readonly/>€</span>
        </span>
        <span v-else>
          <label>Total CA</label>
          <span><input v-model="CATotalLegit" type="number" readonly/>€</span>
        </span>
        <!-- <span>
          <label>Coût Marchandise</label>
          <span><input @focus="costs == 0 ? costs = '' : costs" @blur="costs == '' ? costs = 0 : costs" v-model="costs" type="number"/>€</span>
        </span>
        <span>
          <label>Coefficient</label>
          <span><input v-model="coeff" type="text" readonly/></span>
        </span> -->
      </div>
      <!-- <div class="flex">
        <span class="invis">
          <label>Coût Marchandise</label>
          <span><input type="number"/>€</span>
        </span>
        <span class="invis">
          <label>Coût Marchandise</label>
          <span><input type="number"/>€</span>
        </span>
        <span>
          <label>Total MC</label>
          <span><input v-model="MC" type="number" readonly/>€</span>
        </span>
      </div> -->
      <button @click="save">Enregistrer</button>
      <button @click="reset">Effacer</button>
    </section>
  </main>
</template>

<script>
export default {
  name: 'calculatorComponent',
  props: {
    mavro: Boolean
  },
  data () {
    return {
      CA: {
        cash: 0,
        card: 0,
        tickets: 0,
        checks: 0,
        register: 0,
        mavro: 0
      },
      CATotal: 0,
      costs: 0,
      MC: 0,
      coeff: 'x0',
      nbTickets: 0,
      date: new Date().toLocaleDateString('FR-fr').slice(0, 10)
    }
  },
  computed: {
    tamponDate: {
      get () {
        return this.date
      },
      set (value) {
        this.date = value
      }
    },
    CATotalLegit () {
      return Number(this.CA.cash) + Number(this.CA.card) + Number(this.CA.tickets) + Number(this.CA.checks)
    }
  },
  watch: {
    'CA.cash' (newVal) {
      let val = newVal
      if (newVal === '') {
        val = 0
      }
      if (isNaN(newVal)) {
        val = val.replace(/[A-Za-z]/g, '').replace(',', '.')
      }
      this.CATotal = val + this.CA.card + this.CA.tickets + this.CA.checks
    },
    'CA.card' (newVal) {
      let val = newVal
      if (newVal === '') {
        val = 0
      }
      if (isNaN(newVal)) {
        val = val.replace(/[A-Za-z]/g, '').replace(',', '.')
      }
      this.CATotal = this.CA.cash + val + this.CA.tickets + this.CA.checks
    },
    'CA.tickets' (newVal) {
      let val = newVal
      if (newVal === '') {
        val = 0
      }
      if (isNaN(newVal)) {
        val = val.replace(/[A-Za-z]/g, '').replace(',', '.')
      }
      this.CATotal = this.CA.cash + this.CA.card + val + this.CA.checks
    },
    'CA.checks' (newVal) {
      let val = newVal
      if (newVal === '') {
        val = 0
      }
      if (isNaN(newVal)) {
        val = val.replace(/[A-Za-z]/g, '').replace(',', '.')
      }
      this.CATotal = this.CA.cash + this.CA.card + this.CA.tickets + val
    },
    'CA.register' (newVal) {
      let val = newVal
      if (newVal === '') {
        val = 0
      }
      if (isNaN(newVal)) {
        val = val.replace(/[A-Za-z]/g, '').replace(',', '.')
      }
      this.CA.mavro = this.CATotal - val
    }
  },
  methods: {
    async save () {
      if (this.CA.mavro > this.CA.cash) {
        const result = await this.$swal({
          icon: 'warning',
          title: 'Vérifiez les données',
          text: 'Il y a plus de Mavro que d\'espèces, ça peut poser des erreurs de calcul et des chiffres négatifs',
          confirmButtonText: 'Ça passe',
          showDenyButton: true,
          denyButtonText: 'Je corrige',
          reverseButtons: true
        })
        // console.log(result)
        if (result.isDenied) {
          return
        }
      }
      const isoDate = this.date.split('/')[2] + '-' + this.date.split('/')[1] + '-' + this.date.split('/')[0]
      // console.log(new Date(isoDate).getDay())
      if (new Date(isoDate).getDay() < 2) {
        this.$swal({
          icon: 'error',
          title: 'Impossible d\'insérer des données pour cette date',
          text: 'Vous ne pouvez pas faire de caisse le dimanche ou lundi',
          toast: true,
          timer: 3000
        })
        return
      }

      const sentBody = this.$genFD({
        req: 'registerController',
        action: 'addDay',
        date: this.date,
        CATotal: this.CATotal,
        ISODate: isoDate,
        cash: this.CA.cash,
        card: this.CA.card,
        tickets: this.CA.tickets,
        nbTickets: this.nbTickets,
        checks: this.CA.checks,
        register: this.CA.register,
        mavro: this.CA.mavro
      })

      // fetch
      fetch(this.$baseUrl, {
        body: sentBody,
        method: 'post'
      })
        .then(res => res.json())
        .then(data => {
          if (data.status === 409) {
            this.$swal({
              icon: 'warning',
              toast: true,
              timer: 3000,
              position: 'top-end',
              title: 'Attention, Une caisse existe déjà pour cette date',
              showConfirmButton: false
            })
          } else if (data.status === 201) {
            this.$swal({
              icon: 'success',
              toast: true,
              timer: 3000,
              position: 'top-end',
              title: 'Les comptes ont bien été enregistrés',
              showConfirmButton: false
            })
            this.$emit('updateHistory')
          }
        })
        .catch(err => {
          this.$swal({
            icon: 'error',
            toast: true,
            timer: 3000,
            position: 'top-end',
            title: 'Les comptes n\'ont pas pu être enregistrés',
            html: 'Erreur lors de CalculatorComponent:<save()>. Erreur : ' + err,
            showConfirmButton: false
          })
        })
    },
    reset () {
      this.CA = {
        cash: 0,
        card: 0,
        tickets: 0,
        checks: 0,
        register: 0,
        mavro: 0
      }
      this.nbTickets = 0
    }
  }
}
</script>

<style scoped>
  button {
    padding: 10px;
    background-color: rgb(0, 177, 0);
    color: white;
    border-radius: 10px;
    border: none;
    font-size: larger;
    width: 15rem;
  }
  button:hover {
    cursor: pointer;
    background-color: rgb(0, 158, 0);
  }
  input[readonly] {
    background-color: gainsboro;
  }
  input {
    background-color: whitesmoke;
    padding: 8px;
    border-radius: 8px;
    border: 1px solid gainsboro;
  }
  .invis {
    visibility: hidden;
  }
  div.flex {
    display: flex;
    justify-content: center;
    gap: 1rem;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  #vflex {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
  }
  div>span {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
</style>
