<template>
  <div>
    <!-- {{ this.weeks }} -->
    <h2>
      <span v-show="startWeek">De la semaine du {{ new Date(startWeek).toLocaleDateString('fr-FR') }}</span>
      <span v-show="startWeek && endWeek">à celle du {{ new Date(endWeek).toLocaleDateString('fr-FR') }}</span>
      <button v-show="startWeek && endWeek" @click="buildYearlyReport" class="generate">&#8677;</button>
      <button v-show="startWeek && endWeek" @click="deleteYearlyReport" class="generate">&cross;</button>
    </h2>
    <Spin v-if="loading"/>
    <div class="table-container">

      <table border="1">
        <thead>
          <tr>
            <th>Semaine du :</th>
            <th>Mardi</th>
            <th>Mercredi</th>
            <th>Jeudi</th>
            <th>Vendredi</th>
            <th>Samedi</th>
            <th>Total Semaine<span v-if="mavro"> Déclaré</span></th>
            <th v-if="mavro">Total Semaine Réel</th>
            <th>Coût Marchandise</th>
            <th>Coefficient</th>
            <th>Marge Commerciale</th>
            <th>Rapport</th>
            <th>
              <div class="selectCell">
                <p>Sélectionner</p>
                <p class="fromTo"><span>de</span><span>à</span></p>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <WeekDisplay v-for="(item, index) in weeks" :mavro="mavro" :key="index" :week="item" @showReport="buildWeeklyReport" @defineStart="setStart" @defineEnd="setEnd"/>
        </tbody>
        <tfoot>
          <tr>
            <th>Semaine du :</th>
            <th>Mardi</th>
            <th>Mercredi</th>
            <th>Jeudi</th>
            <th>Vendredi</th>
            <th>Samedi</th>
            <th>Total Semaine<span v-if="mavro"> Déclaré</span></th>
            <th v-if="mavro">Total Semaine Réel</th>
            <th>Coût Marchandise</th>
            <th>Coefficient</th>
            <th>Marge Commerciale</th>
            <th>Rapport</th>
            <th>Sélectionner</th>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>

<script>
import WeekDisplay from './WeekDisplay.vue'
import Spin from './Spin.vue'
export default {
  name: 'weekManager',
  components: {
    WeekDisplay,
    Spin
  },
  props: {
    ready: Boolean,
    update: Boolean,
    mavro: Boolean
  },
  watch: {
    update () {
      this.weeks = []
      this.getHistoryData()
    }
  },
  data () {
    return {
      weeks: [],
      entries: [],
      startWeek: '',
      endWeek: '',
      loading: true
    }
  },
  methods: {
    deleteYearlyReport () {
      this.$emit('deleteYearlyReport')
    },
    setStart (payload) {
      this.startWeek = payload
    },
    setEnd (payload) {
      this.endWeek = payload
    },
    buildYearlyReport () {
      this.$emit('buildYearlyReport', {
        startWeek: this.startWeek,
        endWeek: this.endWeek
      })
    },
    buildWeeklyReport (payload) {
      this.$emit('showReport', payload)
    },
    getHistoryData () {
      const sentBody = this.$genFD({
        req: 'registerController',
        action: 'getHistory'
      })

      fetch(this.$baseUrl, {
        body: sentBody,
        method: 'post'
      })
        .then(res => res.json())
        .then(data => {
          this.entries = data.results
          this.handleWeeks()
        })
        .catch(err => {
          this.$swal({
            icon: 'error',
            toast: true,
            timer: 3000,
            position: 'top-end',
            title: 'Les comptes n\'ont pas pu être Lus',
            html: 'Erreur lors de WeekManager:<getHistoryData()>. Erreur : ' + err,
            showConfirmButton: false
          })
        })
    },
    handleWeeks () {
      let previousDay = 0
      let currentWeek = []
      // console.log(this.entries)
      const sortedEntries = Object.keys(this.entries).sort((a, b) => {
        const [dateANb, monthA, yearA] = a.split('/')
        const dateA = new Date(yearA, monthA - 1, dateANb).toISOString()
        const [dateBNb, monthB, yearB] = b.split('/')
        const dateB = new Date(yearB, monthB - 1, dateBNb).toISOString()
        return dateA > dateB ? 1 : -1
      })

      // the way to do it chilly

      this.loading = true

      let iteration = 0
      const interval = setInterval(() => {
        if (sortedEntries[iteration]) {
          const date = this.entries[sortedEntries[iteration]].date
          const [dateNb, month, year] = date.split('/')
          const dayNb = new Date(year, month - 1, dateNb).getDay()
          // if (date.includes('/09/')) console.log(date)
          if (currentWeek.length === 0) {
            // if (date.includes('/09/')) console.log('first of week')
            // console.log('First of the week')
            currentWeek.push(date)
            previousDay = dayNb
          } else if (dayNb > previousDay) {
            // if (date.includes('/09/')) console.log('in the week', currentWeek)
            // console.log('if(' + dayNb + ' > ' + previousDay + ')')
            // console.log('In the week')
            currentWeek.push(date)
            previousDay = dayNb
          } else {
            // if (date.includes('/09/')) console.log('new week', currentWeek)
            // console.log('new week')
            this.weeks.push(currentWeek)
            previousDay = 2
            currentWeek = []
            currentWeek.push(date)
            console.log(currentWeek)
          }
          iteration++
        } else {
          this.weeks.push(currentWeek)
          clearInterval(interval)
          this.loading = false
        }
      }, 100)

      // the way to do it quickly,

      // for (const day in sortedEntries) {
      //   const date = this.entries[sortedEntries[day]].date
      //   const [dateNb, month, year] = date.split('/')
      //   const dayNb = new Date(year, month - 1, dateNb).getDay()
      //   // console.log(date, dayNb, previousDay)
      //   if (currentWeek.length === 0) {
      //     // console.log('First of the week')
      //     currentWeek.push(date)
      //     previousDay = dayNb
      //   } else if (dayNb > previousDay) {
      //     // console.log('if(' + dayNb + ' > ' + previousDay + ')')
      //     // console.log('In the week')
      //     currentWeek.push(date)
      //     previousDay = dayNb
      //   } else {
      //     // console.log('new week')
      //     this.weeks.push(currentWeek)
      //     previousDay = 2
      //     currentWeek = []
      //     currentWeek.push(date)
      //   }
      // }

      if (currentWeek.length > 0) {
        this.weeks.push(currentWeek)
      }
    }
  },
  mounted () {
    this.getHistoryData()
  }
}
</script>

<style scoped>
  table {
    width: 90%;
    border-collapse: collapse;
  }
  thead, tfoot {
    background-color: rgb(201, 201, 201);
  }
  th {
    padding: 10px;
  }
  td {
    padding: 5px;
  }
  tbody > tr:nth-child(even) {
    background-color: gainsboro;
  }
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 1rem;
  }
  .selectCell {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .fromTo {
    width: 100%;
    display: flex;
    justify-content: space-around;
  }
  p {
    margin: 5px 0;
  }
  .generate {
    border-radius: 8px;
    background-color: dimgray;
    color: white;
    font-weight: bold;
    border: 0;
    padding: 5px;
    font-size: 1em;
  }
  .generate:hover {
    cursor: pointer;
    background-color: rgb(67, 67, 67);
  }
  h2 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
  @media screen and (max-width: 1200px) {
    table {
      display: block;
      overflow-x: auto;
    }
  }
</style>
