<template>
  <div class="spin">
    &#10227;
  </div>
</template>

<script>
// import store from '@/store'
export default {
  name: 'SpinLogo'
}
</script>

<style scoped>
.spin {
  font-size: 5vw;
  animation: 3s linear 1s infinite spin;
}

@keyframes spin {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}
</style>
