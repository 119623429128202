<template>
  <div>
    <!-- {{ entries }} -->
    <table border="1">
      <thead>
        <th>Date</th>
        <th>Espèce</th>
        <th>Carte Bancaire</th>
        <th>Tickets Restaurants</th>
        <th>Chèques</th>
        <th v-if="mavro">Recette Totale</th>
        <th v-if="mavro">Recette Mavro</th>
        <th>Recette Totale<span v-if="mavro"> déclarée</span></th>
        <th>Supprimer</th>
      </thead>
      <tbody>
        <tr v-for="date in sortedEntries" :key="date">
          <td>{{ entries[date].date }}</td>
          <!-- <td class="flex"> -->
          <td>{{ entries[date].cash }}<img src="@/assets/money-bill-wave-solid.svg" title="Espèces"/></td>
          <td>{{ entries[date].card }}<img src="@/assets/credit-card-solid.svg" title="Carte Bleue"/></td>
          <td>{{ entries[date].tickets }}<img src="@/assets/ticket-solid.svg" title="Titres Restaurants"/></td>
          <td>{{ entries[date].checks }}<img src="@/assets/money-check-dollar-solid.svg" title="Chèques"/></td>
          <td v-if="mavro">{{ Math.floor(entries[date].CATotal * 100) / 100 }}<img src="@/assets/cash-register-solid.svg" title="CATotal"/></td>
          <td v-if="mavro">{{ Math.floor(entries[date].mavro * 100) / 100 }}<img src="@/assets/people-robbery-solid.svg" title="Noir"/></td>
          <td class="total">{{ (entries[date].CATotal - entries[date].mavro).toFixed(2) }}<img src="@/assets/file-invoice-solid.svg" title="Total déclaré"/></td>
          <!-- </td>
          <td>{{ entries[date].costs }}</td>
          <td>{{ entries[date].MC }}</td>
          <td>{{ entries[date].coeff }}</td> -->
          <td class="trash" @click="remove(entries[date].date)"><img src="@/assets/trash-solid.svg"/></td>
        </tr>
      </tbody>
      <tfoot>
        <th>Date</th>
        <th>Espèce</th>
        <th>Carte Bancaire</th>
        <th>Tickets Restaurants</th>
        <th>Chèques</th>
        <th v-if="mavro">Recette Totale</th>
        <th v-if="mavro">Recette Mavro</th>
        <th>Recette Totale<span v-if="mavro"> déclarée</span></th>
        <th>Supprimer</th>
      </tfoot>
    </table>
  </div>
</template>

<script>
export default {
  name: 'HistoryComponent',
  props: {
    update: Boolean,
    mavro: Boolean
  },
  data () {
    return {
      entries: {},
      sortedEntries: []
    }
  },
  watch: {
    update () {
      this.getHistoryData()
    }
  },
  methods: {
    getHistoryData () {
      const sentBody = this.$genFD({
        req: 'registerController',
        action: 'getHistory'
      })

      fetch(this.$baseUrl, {
        body: sentBody,
        method: 'post'
      })
        .then(res => res.json())
        .then(data => {
          this.entries = data.results
          if (Object.keys(this.entries).length > 0) {
            this.sortedEntries = Object.keys(this.entries).sort((a, b) => {
              const [dateANb, monthA, yearA] = a.split('/')
              const dateA = new Date(yearA, monthA - 1, dateANb).toISOString()
              const [dateBNb, monthB, yearB] = b.split('/')
              const dateB = new Date(yearB, monthB - 1, dateBNb).toISOString()
              return dateA > dateB ? 1 : -1
            })
          }
        })
        .catch(err => {
          this.$swal({
            icon: 'error',
            toast: true,
            timer: 3000,
            position: 'top-end',
            title: 'Les comptes n\'ont pas pu être Lus',
            html: 'Erreur lors de HistoryComponent:<getHistoryData()>. Erreur : ' + err,
            showConfirmButton: false
          })
        })
    },
    remove (date) {
      // console.log(date)
      const sentBody = this.$genFD({
        req: 'registerController',
        action: 'delDay',
        date
      })

      // fetch
      fetch(this.$baseUrl, {
        body: sentBody,
        method: 'post'
      })
        .then(res => res.json())
        .then(data => {
          this.getHistoryData()
          this.$emit('deleted')
        })
        .catch(err => {
          this.$swal({
            icon: 'error',
            toast: true,
            timer: 3000,
            position: 'top-end',
            title: 'Les comptes n\'ont pas pu être enregistrés',
            html: 'Erreur lors de HistoryComponent:<remove()>. Erreur : ' + err,
            showConfirmButton: false
          })
        })
    }
  },
  mounted () {
    this.getHistoryData()
  }
}
</script>

<style scoped>
  table {
    width: 90%;
    border-collapse: collapse;
  }
  thead, tfoot {
    background-color: rgb(201, 201, 201);
  }
  th {
    padding: 10px;
  }
  td {
    padding: 5px;
  }
  tbody > tr:nth-child(even) {
    background-color: gainsboro;
  }
  div {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 1rem;
  }
  td > tr > td {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 10px);
  }
  img {
    width: 25px;
    height: 17px;
  }
  td.flex {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
  }
  td.trash {
    background-color: red;
  }
  td.trash img {
    filter: invert(1);
    transform: scale(1.5);
  }
  td.trash:hover {
    cursor: pointer;
    background-color: rgb(217, 0, 0);
  }
  .total {
    background-color: black;
    color: white;
    font-weight: bold;
  }
  .total img {
    filter: invert(1);
  }
  @media screen and (max-width: 1200px) {
    table {
      display: block;
      overflow-x: auto;
    }
  }
</style>
