<template>
  <div class="paper" id="paper" style="width: 21cm; height: 29cm; border: 1px solid black;">
    <h1 style="text-align: center; margin-left: auto; margin-right: auto;">Semaine du {{ week[Object.keys(week)[0]] }} au {{ week[Object.keys(week).at(-1)] }}</h1>
    <!-- <p>{{ week }}</p>
    <span>{{ weekData }}</span>
    <span>{{ total }}</span> -->
    <div class="table-container" style="display: flex; justify-content: space-evenly; align-items: center;">
      <table border="1" style="border-collapse: collapse; width: 20%;">
        <thead style="background-color: rgb(201, 201, 201);">
          <tr>
            <th style="padding: 10px;">Espèces</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style="padding: 5px;">{{ Math.floor(total.cash * 100) / 100 }}€</td>
          </tr>
        </tbody>
      </table>
      <table border="1" style="border-collapse: collapse; width: 20%;">
        <thead style="background-color: rgb(201, 201, 201);">
          <tr>
            <th style="padding: 10px;">Carte Bleu</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style="padding: 5px;">{{ Math.floor(total.card * 100) / 100 }}€</td>
          </tr>
        </tbody>
      </table>
      <table border="1" style="border-collapse: collapse; width: 20%;">
        <thead style="background-color: rgb(201, 201, 201);">
          <tr>
            <th style="padding: 10px;">Tickets resto</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style="padding: 5px;">{{ Math.floor(total.tickets * 100) / 100 }}€</td>
          </tr>
        </tbody>
      </table>
      <table border="1" style="border-collapse: collapse; width: 20%;">
        <thead style="background-color: rgb(201, 201, 201);">
          <tr>
            <th style="padding: 10px;">Chèques</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style="padding: 5px;">{{ Math.floor(total.checks * 100) / 100 }}€</td>
          </tr>
        </tbody>
      </table>
    </div>
    <table border="1" style="border-collapse: collapse; width: 90%;">
      <thead style="background-color: rgb(201, 201, 201);">
        <tr>
          <th style="padding: 10px;">Chiffre d'affaire</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style="padding: 5px;">{{ Math.floor(total.total * 100) / 100 }}€</td>
        </tr>
      </tbody>
    </table>
    <!-- <table border="1" style="border-collapse: collapse; width: 90%;">
      <thead style="background-color: rgb(201, 201, 201);">
        <tr>
          <th style="padding: 10px;">Marchandise</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style="padding: 5px;">{{ marchandise }}€</td>
        </tr>
      </tbody>
    </table>
    <table border="1" style="border-collapse: collapse; width: 90%;">
      <thead style="background-color: rgb(201, 201, 201);">
        <tr>
          <th style="padding: 10px;">Marge Commerciale</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style="padding: 5px;">{{ total.total - marchandise }}€</td>
        </tr>
      </tbody>
    </table> -->
  </div>
</template>

<script>
import html2pdf from 'html2pdf.js'

export default {
  name: 'weeklyReport',
  props: {
    week: Object
  },
  data () {
    return {
      weekData: {},
      total: {
        cash: 0,
        card: 0,
        tickets: 0,
        checks: 0,
        total: 0
      },
      marchandise: 0
    }
  },
  methods: {
    exportToPDF () {
      html2pdf(document.getElementById('paper'))
    },
    getWeeklyData () {
      const dayList = []
      for (let day in this.week) {
        day = this.week[day]
        dayList.push(day)
      }

      const sentBody = this.$genFD({
        req: 'registerController',
        action: 'getWeeklyData',
        days: JSON.stringify(dayList)
      })

      fetch(this.$baseUrl, {
        body: sentBody,
        method: 'post'
      })
        .then(res => res.json())
        .then(data => {
          for (let day in this.week) {
            day = this.week[day]
            this.weekData[day] = Object.values(data.results).find(dayData => dayData.date === day)
          }
          this.calculateTotal()
        })
        .catch(err => {
          this.$swal({
            icon: 'error',
            toast: true,
            timer: 3000,
            position: 'top-end',
            title: 'Les comptes n\'ont pas pu être Lus',
            html: 'Erreur lors de WeeklyReport:<getWeeklyData()>. Erreur : ' + err,
            showConfirmButton: false
          })
          console.error(err)
        })
    },
    calculateTotal () {
      if (Object.keys(this.weekData).length === 0) {
        return
      }
      this.total.cash = 0
      this.total.card = 0
      this.total.tickets = 0
      this.total.checks = 0
      this.total.total = 0
      this.total.mavro = 0
      for (let day in Object.keys(this.weekData)) {
        const key = Object.keys(this.weekData)[day]
        day = this.weekData[key]
        if (day.cash - day.mavro < 0) {
          // console.log('warning')
        }
        this.total.cash += (day.cash - day.mavro)
        this.total.card += Number(day.card)
        this.total.tickets += Number(day.tickets)
        this.total.checks += Number(day.checks)
        this.total.mavro += Number(day.mavro)
      }
      this.total.total = this.total.cash + this.total.card + this.total.tickets + this.total.checks
    }
  },
  created () {
    this.getWeeklyData()
  },
  mounted () {
    if (this.week?.length > 0) {
      setTimeout(() => {
        this.exportToPDF()
      }, 1000)
    }
  }
}
</script>

<style scoped>
  .paper {
    width: 21cm;
    height: 29.7cm;
    border: 1px solid black;
  }
  .table-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .table-container table {
    width: 20%;
  }
  table:not(.table-container table) {
    width: 90%;
    margin: auto;
    margin-top: 1rem;
  }
  h1 {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
</style>
