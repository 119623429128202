import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import VueSweetalert2 from 'vue-sweetalert2'

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css'

class Util {
  static install (vue, options = {}) {
    const baseUrl = 'https://compta.lucasgrd.fr/db/index.php'
    function generateFormData (data) {
      const bodyFormData = new FormData()
      try {
        Object.keys(data).forEach(key => {
          if (typeof data[key] === 'string' || typeof data[key] === 'number') {
            bodyFormData.set(key, data[key])
          } else if (typeof data[key] === 'object' && data[key] !== null) {
            try {
              data[key].forEach(elem => {
                bodyFormData.append('key', elem)
              })
            } catch (e) {
              console.error('data: ' + JSON.stringify(data), 'key: ' + key, 'data[key]: ' + data[key], 'error: ' + e)
            }
          }
        })
      } catch (e) {
        console.error('data: ' + JSON.stringify(data), 'error: ' + e)
      }
      return bodyFormData
    }

    if (vue.config?.globalProperties && !vue.config.globalProperties.$genFD) {
      // vue 3
      vue.config.globalProperties.$genFD = generateFormData
      vue.provide('$genFD', generateFormData)
    } else if (!Object.prototype.hasOwnProperty.call(vue, '$genFD')) {
      // vue 2

      // eslint-disable-next-line
      vue.prototype.$genFD = generateFormData
      vue.genFD = generateFormData
    }

    if (vue.config?.globalProperties && !vue.config.globalProperties.$baseUrl) {
      // vue 3
      vue.config.globalProperties.$baseUrl = baseUrl
      vue.provide('$baseUrl', baseUrl)
    } else if (!Object.prototype.hasOwnProperty.call(vue, '$baseUrl')) {
      // vue 2

      // eslint-disable-next-line
      vue.prototype.$baseUrl = baseUrl
      vue.baseUrl = baseUrl
    }
  }
}

createApp(App)
  .use(VueSweetalert2)
  .use(Util)
  .mount('#app')
