<template>
  <tr>
    <td>{{ week[0] }}</td>
    <DayDisplay v-for="count in 5" :key="count" :day="currentWeek[count]" :daysDataList="daysDataList" @gotTotal.once="setTotal" @resetTotal="weekTotalDeclared = 0"/>
    <!-- "{{ merchCost }}" -->
    <td>{{ Math.floor(weekTotalDeclared * 100) / 100 }}</td>
    <td v-if="mavro"> {{ Math.floor(weekTotalMavro * 100) / 100 }}</td>
    <td v-if="initialMerchCost == 0 && !declaring"><button class="full-size" @click="declar()"><img width="20" height="20" src="@/assets/circle-plus-solid.svg"/></button></td>
    <td v-else-if="declaring"><input ref="merchCost" @focus="merchCost == 0 ? merchCost = '' : merchCost" type="text" v-model="merchCost"/><button @click="setMerchCost(true)" class="btn green">&check;</button><button @click="setMerchCost(false)" class="btn red">&cross;</button></td>
    <td v-else><button class="full-size" @click="declar()">{{ merchCost }}</button></td>
    <td v-if="mavro">{{ Math.floor((weekTotalMavro / merchCost) * 100) / 100 }}</td> <!--coeff-->
    <td v-else>{{ Math.floor((weekTotalDeclared / merchCost) * 100) / 100 }}</td>
    <td v-if="mavro">{{ Math.floor((weekTotalMavro - merchCost) * 100) / 100 }}</td> <!--Marge-->
    <td v-else>{{ Math.floor((weekTotalDeclared - merchCost) * 100) / 100 }}</td>
    <td class="download" @click="buildWeeklyReport"><img width="20" height="20" src="@/assets/download-solid.svg"/></td>
    <td>
      <div class="radioCell">
        <input type="radio" name="start" id="startRadio" @click="defineStart"><input type="radio" name="end" id="endRadio" @click="defineEnd">
      </div>
    </td>
  </tr>
</template>

<script>
import DayDisplay from './DayDisplay.vue'

export default {
  name: 'weekDisplay',
  components: {
    DayDisplay
  },
  props: {
    week: Array,
    mavro: Boolean
  },
  data () {
    return {
      currentWeek: {},
      weekTotalDeclared: 0,
      weekTotalMavro: 0,
      merchCost: 0,
      declaring: false,
      daysDataList: [],
      initialMerchCost: 0
    }
  },
  watch: {
    merchCost (newVal, oldVal) {
      if (newVal === oldVal) {
        return
      }
      let val = newVal
      if (isNaN(newVal)) {
        val = val.replace(/[A-Za-z]/g, '').replace(',', '.').replace('?', '.').replace(';', '.')
      }
      this.merchCost = val
    }
  },
  methods: {
    defineStart () {
      const isoDate = this.week.at(0).split('/')[2] + '-' + this.week.at(0).split('/')[1] + '-' + this.week.at(0).split('/')[0]
      this.$emit('defineStart', isoDate)
    },
    defineEnd () {
      // console.log(this.week.at(-1))
      const isoDate = this.week.at(-1).split('/')[2] + '-' + this.week.at(-1).split('/')[1] + '-' + this.week.at(-1).split('/')[0]
      // console.log(isoDate)
      this.$emit('defineEnd', isoDate)
    },
    declar () {
      this.declaring = !this.declaring
      if (this.declaring) {
        setTimeout(() => {
          this.$refs.merchCost.focus()
        }, 100)
      }
    },
    getMerchCost () { // deprecated, unused anymore
      const sentBody = this.$genFD({
        req: 'registerController',
        action: 'getWeekCost',
        date: this.week[0]
      })

      fetch(this.$baseUrl, {
        body: sentBody,
        method: 'post'
      })
        .then(res => res.json())
        .then(data => {
          if (data.status !== 200) {
            console.error('error in getMerchCost')
          } else {
            this.merchCost = data.merchCost ?? 0
            this.initialMerchCost = this.merchCost
            console.log(this.initialMerchCost, this.merchCost)
          }
        })
        .catch(err => {
          this.$swal({
            icon: 'error',
            toast: true,
            timer: 3000,
            position: 'top-end',
            title: 'Les comptes n\'ont pas pu être enregistrés',
            html: 'Erreur lors de WeekDisplay:<getMerchCost()>. Erreur : ' + err,
            showConfirmButton: false
          })
        })
    },
    setMerchCost (validate = false) {
      this.declaring = false
      if (validate === false) {
        this.merchCost = this.initialMerchCost
        return
      }

      const sentBody = this.$genFD({
        req: 'registerController',
        action: 'addWeek',
        date: this.week[0],
        costs: this.merchCost
      })

      // fetch
      fetch(this.$baseUrl, {
        body: sentBody,
        method: 'post'
      })
        .then(res => res.json())
        .then(data => {
          if (data.status !== 201) {
            console.error('error in setMerchCost')
            throw new Error()
          } else {
            this.initialMerchCost = this.merchCost
          }
        })
        .catch(err => {
          this.$swal({
            icon: 'error',
            toast: true,
            timer: 3000,
            position: 'top-end',
            title: 'Les comptes n\'ont pas pu être enregistrés',
            html: 'Erreur lors de WeekDisplay:<setMerchCost()>. Erreur : ' + err,
            showConfirmButton: false
          })
        })
    },
    buildWeeklyReport () {
      this.$emit('showReport', this.currentWeek)
    },
    setTotal (payload) {
      this.weekTotalDeclared += Number(payload.register)
      this.weekTotalMavro += Number(payload.register) + Number(payload.mavro)
    },
    showMissingDays () {
      // if (this.week.length === 5) {
      //   this.currentWeek = this.week
      //   return
      // }
      // if (this.week.length > 5) {
      //   this.currentWeek = this.week
      //   return
      // }
      const currentWeek = {}
      // console.log('-------------------------------------------------')
      // console.log(this.week)
      for (const weekDay in this.week) {
        const date = this.week[weekDay]
        const [dateNb, month, year] = date.split('/')
        const jsDate = new Date(year, month - 1, dateNb)
        const dayIndex = jsDate.getDay()
        currentWeek[dayIndex - 1] = jsDate.toLocaleDateString('fr-FR')
      }
      // console.log(currentWeek)
      // console.log('-------------------------------------------------')
      this.currentWeek = currentWeek
    },
    getDays () {
      const sentBody = this.$genFD({
        req: 'registerController',
        action: 'getDays',
        dates: '(\'' + this.week.join('\', \'') + '\')'
      })

      fetch(this.$baseUrl, {
        body: sentBody,
        method: 'post'
      })
        .then(res => res.json())
        .then(data => {
          this.daysDataList = data.results
          this.merchCost = data.merchCost ?? 0
          this.initialMerchCost = this.merchCost
        })
        .catch(err => {
          this.$swal({
            icon: 'error',
            toast: true,
            timer: 3000,
            position: 'top-end',
            title: 'Les comptes n\'ont pas pu être Lus',
            html: 'Erreur lors de WeekDisplay:<getDays()>. Erreur : ' + err,
            showConfirmButton: false
          })
        })
    }
  },
  created () {
    this.getDays()
    this.showMissingDays()
  }
}
</script>

<style scoped>
  th {
    padding: 10px;
  }
  td {
    padding: 5px;
  }
  .radioCell {
    display: flex;
  }
  .download {
    background-color: DimGray;
  }
  .download:hover {
    cursor: pointer;
    background-color: rgb(67, 67, 67);
  }
  .download img {
    filter: invert(1);
  }
  button.full-size {
    width: 100%;
    height: 100%;
  }
  button.green {
    /*green*/
    background-color: magenta;
    filter: invert(1);
    border: none;
    font-weight: bold;
    width: 30px;
    aspect-ratio: 1;
  }
  .green:hover {
    background-color: rgb(255, 65, 255);
  }
  .red:hover {
    background-color: rgb(152, 244, 244);
  }
  button.red {
    /*red*/
    background-color: cyan;
    font-weight: bold;
    filter: invert(1);
    border: none;
    width: 30px;
    aspect-ratio: 1;
  }
  .btn {
    border-radius: 50%;
    aspect-ratio: 1;
    font-weight: 900;
  }
  input {
    background-color: whitesmoke;
    padding: 8px;
    border-radius: 8px;
    border: 1px solid gainsboro;
    width: 50px;
  }
  input[type=radio] {
    width: 100%;
    margin: 0 3px;
  }
  button:hover {
    cursor: pointer;
  }
</style>
